/*
Utils
*/
// Grid controls
.u-layout {
  display: grid;
  grid-template-columns:
    [full-start]
      minmax( 0.5rem, calc( 50vw - 34rem ) )
      [main-content-start] repeat( 12, 1fr ) [main-content-end]
      minmax( 0.5rem, calc( 50vw - 34rem ) )
    [full-end];
  grid-column-gap: var(--layout-gap-variable);
}
.u-layout > * {
  grid-column: main-content;
}
.u-layout > .u-layout:not(.footer-bg) {
  grid-template-columns: [main-content-start] repeat( 12, 1fr ) [main-content-end];
}

// “Flow” content
.u-flow > * {
  margin: 0;
}
.u-flow > * + * {
  margin-top: var(--flow-space, 1em);
}
.u-flow {
  max-width: 32em;
}
@media (min-width: 50em) {
  .u-flow {
    max-width: inherit;
  }
}

// “Hug” helper classes — overrides specific alignment rules
.u-nogap-intro[class] {
  margin-top: 0;
}



/*

a11y helpers

*/
.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
[hidden],
.squelch {
  display: none;
}
