
@import "type";
@import "utils";

:root {
  --layout-gap-variable: clamp( 0.25em, 1vw, 1em );
  --theme-transition--color: 4s;

  --logo-petals--outer-top: #FF91A1;
  --logo-petals--mid-top: #FF9C27;
  --logo-petals--overlay-inner: #FF5A19;
  --logo-petals--outer-bottom: #EF2C06;

  --color-bg: #FFF4E5;
  --color-text: #631000;
  --color-accent: #FDE0D4;
  --color-accent-alt: #FAC78A;
  --color-peak-fg: #492F2A;
  --color-peak-bg: #7D5249;
  --color-link-marker: var(--logo-petals--mid-top);
  --color-promo-logo-bg: #FFF;
}
[data-site-theme="orange"] {
  --logo-petals--outer-top: #FF91A1;
  --logo-petals--mid-top: #FF9C27;
  --logo-petals--overlay-inner: #FF5A19;
  --logo-petals--outer-bottom: #EF2C06;

  --color-bg: #FFF4E5;
  --color-text: #631000;
  --color-accent: #FDE0D4;
  --color-accent-alt: #FAC78A;
  --color-peak-fg: #492F2A;
  --color-peak-bg: #7D5249;
}
[data-site-theme="green"] {
  --logo-petals--outer-top: #A6C171;
  --logo-petals--mid-top: #8CB89A;
  --logo-petals--overlay-inner: #5F8B47;
  --logo-petals--outer-bottom: #3F6C32;

  --color-bg: #FDFFE5;
  --color-text: #003B1A;
  --color-accent: #CFE1D0;
  --color-accent-alt: #E9EFC8;
  --color-peak-fg: #394B41;
  --color-peak-bg: #678172;
}
[data-site-theme="blue"] {
  --logo-petals--outer-top: #9594CD;
  --logo-petals--mid-top: #7B9FCF;
  --logo-petals--overlay-inner: #626DAD;
  --logo-petals--outer-bottom: #355492;

  --color-bg: #FFF9F1;
  --color-text: #1B355E;
  --color-accent: #D4E5FD;
  --color-accent-alt: #B5C4FD;
  --color-peak-fg: #39404B;
  --color-peak-bg: #6E7785;
}
[data-site-theme="brown"] {
  --logo-petals--outer-top: #BD965D;
  --logo-petals--mid-top: #B6A24A;
  --logo-petals--overlay-inner: #9B7E35;
  --logo-petals--outer-bottom: #7E602D;

  --color-bg: #FFF3EF;
  --color-text: #5C3901;
  --color-accent: #F3D3AC;
  --color-accent-alt: #EDCACA;
  --color-peak-fg: #492A44;
  --color-peak-bg: #625C72;
}
@mixin dark-theme {
  --logo-petals--outer-top: hsl( 360deg, 0%, 100% );
  --logo-petals--mid-top: hsl( 360deg, 0%, 90% );
  --logo-petals--overlay-inner: hsl( 360deg, 0%, 80% );
  --logo-petals--outer-bottom: hsl( 360deg, 0%, 70% );

  --color-bg: #3B2960;
  --color-text: #D5CEE5;
  --color-accent: #623193;
  --color-accent-alt: #55235F;
  --color-peak-fg: #411A4C;
  --color-peak-bg: #331A53;
  // Alternate tokens for petals
  --color-petal-1: #ca5252;
  --color-petal-2: #7655b9;
  --color-petal-3: #ccc;
  --color-petal-4: #a552ca;
  // “Alternate” token for promo
  --color-promo-logo-bg: #A8A8A8;
  // “Alternate” tokens for testimonials
  --color-card-text: #FFF;
  --color-card-bg: hsla(0deg, 0%, 4%, 0.5);
  --color-card-bg-alt: hsla(0deg, 0%, 0%, 0.2);
  --color-card-accent: #BF5CB5;
}
@media ( prefers-color-scheme: dark ) {
  :root {
    @include dark-theme;
  }
  :root[data-site-theme] {
    @include dark-theme;
  }
}


/*

Baseline styles

*/
body {
  background: #FFF;
  color: #222;
  font: 350 100%/1.45 "Inter", -system-ui, Helvetica, Arial, sans-serif;
  margin: 0 auto;
  max-width: 22.5rem;
  text-decoration-thickness: from-font;
  text-underline-position: from-font;
}
@supports (display: grid) and (--prop: value) {
  html {
    font-size: clamp( 100%, calc( 100% + 0.01vw ), 200% );
  }
  body {
    background-color: var(--color-bg);
    color: var(--color-text);
    margin: 0;
    max-width: inherit;
    transition: color var(--theme-transition--color), background-color var(--theme-transition--color);
  }
}
a {
  color: inherit;
  font-weight: 700;
}
a:focus,
a:hover {
  text-decoration: none;
}
a:focus {
  outline: thin outset;
}
img,
svg {
  max-width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
button,
input,
textarea {
  font-size: inherit;
  font-family: inherit;
}
button {
  cursor: pointer;
}
button[type="submit"],
button[type="button"] {
  background: var(--color-text);
  color: var(--color-bg);
  box-shadow:
    0 0 0 0 var( --color-petal-1, var(--logo-petals--mid-top) ),
    0 0 0 0 var( --color-petal-2, var(--logo-petals--outer-bottom) );
  border: none;
  border-radius: 0.25em;
  padding: 0.5em 0.75em;
  transition: background-color 0.5s, box-shadow 0.5s;
}
button[type="submit"]:hover,
button[type="button"]:hover,
button[type="submit"]:focus,
button[type="button"]:focus {
  background: var(--color-bg);
  box-shadow:
    0 0 0 0.2em var( --color-petal-1, var(--logo-petals--mid-top) ),
    0 0 0 0.45em var( --color-petal-2, var(--logo-petals--outer-bottom) );
  color: inherit;
}
ol[class],
ul[class] {
  list-style: none;
  margin: 0;
  padding: 0;
}
fieldset {
  border: none;
  padding: 0;
}
main:focus {
  outline: none;
}
cite,
em {
  font-style: inherit;
  font-variation-settings: "slnt" -10;
}
// mandy added this part and acknowledges that there is probably a better way to do it but i have but one life and it is already long in the tooth
span.intro {
	font-size: 26px;
}

/*

Headline treatments

*/
// “Chapter” headlines, mainly used on homepage sections
.hed-chapter {
  font-size: 2.5rem;
  line-height: 1;
  margin: 3rem 0 1rem;
}
@media (min-width: 50em) {
  .hed-chapter {
    font-size: 3.625rem;
    line-height: 1;
    margin: 3rem 0;
  }
}
// “Primary” headlines, used for page-level headlines (but not on article pages),
// or for high-impact headlines (i.e., the ec promo module)
.hed-primary {
  font-size: 2.5rem;
  line-height: 1;
  margin-bottom: 1em;
  margin-top: 0;
}
@media (min-width: 50em) {
  .hed-primary {
    font-size: 3.625rem;
    grid-column-end: span 6;
    margin-bottom: 2em;
  }
}



/*

Header

*/
.site-mast {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem 2rem;
  justify-content: space-between;
  padding: calc( 1rem + 2.5vw ) 0 calc( 1rem + 5vw );
  margin-bottom: 1.5rem;
}
@media (min-width: 40em) {
  .site-mast {
    margin-bottom: 0;
  }
}
.site-logo {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.site-logo svg {
  height: auto;
}
.logo-mark {
  width: 70px;
}
.logo-wordmark {
  margin-bottom: -7px;
}
@media (min-width: 30em) {
  .logo-wordmark {
    max-width: 245px;
  }
}
.sect-home .logo-wordmark {
  display: none;
}
@media (max-width: 22em) {
  .site-mast nav {
    flex: 1;
  }
}
.site-nav {
  text-align: right;
}
.site-nav[class] {
  margin-right: -0.5em;
}
@media (min-width: 29em) {
  .site-nav {
    column-gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    font-size: 1rem;
    line-height: 1;
    text-align: left;
  }
  .site-nav[class] {
    margin-left: -0.5em;
    margin-right: 0;
  }
}
@media (min-width: 40em) {
  .site-nav {
    gap: 2rem;
  }
}
.nav-link {
  font-weight: inherit;
  padding: 0.5em;
  text-decoration: none;
}
.nav-link:focus,
.nav-link:hover {
  text-decoration: underline;
}
.nav-link[aria-current] {
  font-weight: 700;
}
.nav-link[aria-current]:focus,
.nav-link[aria-current]:hover {
  text-decoration: none;
}



/*

Default spacing rules for main content modules

*/
main > * + * {
  margin-top: 4rem;
}



/*

Footer

*/
footer {
  color: #FFF;
  position: relative;
}

// Footer logo
.footer-logo {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media (min-width: 64em) {
  .footer-logo {
    padding: 160px 0 90px;
  }
}

// Newsletter ubscription
.site-subscribe {
  background: hsla(0deg, 0%, 0%, 0.6);
  max-width: 40em;
  margin-bottom: 1rem;
}
@media (max-width: 40em) {
  .site-subscribe {
    grid-column: full;
  }
}
@media (min-width: 64em) {
  .site-subscribe {
    margin-left: -1em;
  }
}
.subscribe-header,
.subscribe-content {
  font-size: 1rem;
  padding: 1em;
}
.subscribe-header {
  font-weight: bold;
  margin: 0;
  font-size: 1.125rem; /* 18/16 */
  padding-bottom: 0;
}
.subscribe-content {
  padding-top: 0.5em;
}
.subscribe-fields {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  margin-top: 0.5em;
}
.subscribe-email {
  flex: 1;
  border: 1px solid #CCC;
  background-color: transparent;
  color: inherit;
  padding: 0.3rem;
}
.subscribe-email:focus {
  border-color: #FFF;
  outline: none;
}
.subscribe-btn {
  --color-bg: #414042;
  --color-text: #FFF;
  border: none;
  border-radius: 0.3em;
  font-weight: bold;
  font-size: 0.875rem;
  padding: 0.3rem 1.3rem;
  text-transform: uppercase;
}
.subscribe-email:focus + .subscribe-btn {
  background-color: #505050;
}
.subscribe-btn:focus,
.subscribe-btn:hover {
  background-color: #777;
  outline: none;
}

// Footer nav+copyright
.footer-utils {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.85em;
  gap: 1em 2em;
  padding: 1em 0;
}
.footer-utils > * {
  margin: 0;
}
.footer-utils nav {
  flex: 1;
}
.footer-nav {
  align-self: center;
  display: flex;
  gap: 1em;
}
.footer-nav-link {
  font-weight: normal;
}
.footer-nav-link[aria-current] {
  font-weight: 700;
}
// “Stage” for drawing in our footer bg — the peaks, and the horizon/bar/thing
.site-footer {
  background-color: #414042;
  box-shadow:
    0 0 0 15px #BBBDBF,
    0 0 0 30px #D8D8D8;
  margin-top: 30px;
  color: #FFF;
}
@media (min-width: 64em) {
  .site-footer {
    margin-top: 4rem;
  }
}
@supports (display: grid) and (--prop: value) {
  .site-footer {
    background-color: transparent;
    box-shadow: none;
    padding-top: 2rem;
  }
  .footer-bg {
    bottom: 0;
    grid-column-gap: 0;
    grid-column: full;
    grid-template-rows: 2rem 2rem 1fr;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  .peak-l,
  .peak-r {
    grid-column: 1 / -1;
    transition: background-color var(--theme-transition--color);
  }
  .peak-l {
    background: var(--color-peak-fg, #414042);
    grid-row: 1 / -1;
    z-index: 100;
  }
  .peak-r {
    background: var(--color-peak-bg, #BBBDBF);
    grid-row: 2 / -1;
    z-index: 10;
  }
  .peak-l-1,
  .peak-r-2 {
    display: none;
  }
  .peak-l-2 {
    clip-path: polygon(0% 0%, 0% 0%, 100% 8rem, 100% 100%, 0% 100%);
  }
  .peak-r-1 {
    clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 0% 10rem);
  }
  .grade {
    background-color: var(--color-accent-alt, #D8D8D8);
    transition: background-color var(--theme-transition--color);
    grid-column: full;
    grid-row: 3 / -1;
    z-index: 1;
  }
  // Footer adjacent
  .has--footer-cap > :last-child {
    background-color: #D8D8D8;
    background-color: var(--color-accent-alt, #D8D8D8);
  }
  // If .has--footer-cap is present, the “grade” background should be
  // drawn to the top of the footer, visually “connecting” the footer
  // with the content block
  .has--footer-cap + footer .grade {
    grid-row: 1 / -1;
  }
  // If .has--footer-cap is present, remove the gap between the footer and the content area
  .has--footer-cap + .site-footer {
    margin-top: 0;
  }
  @media (min-width: 64em) {
    .footer-bg {
      grid-template-rows: 7em 2em 1fr;
    }
    // Reset the display on any peaks that were hidden
    .peak-l,
    .peak-r {
      display: initial;
    }
    .peak-l-1 {
      grid-column: full-start / main-content-start;
      clip-path: polygon(0% 25%, 100% 0%, 100% 100%, 0% 100%);
      margin-right: -1px; /* This is a terrible fix for Chrome (#35), and I feel terrible writing it. — EM */
    }
    .peak-l-2 {
      grid-column: main-content-start / full-end;
      clip-path: polygon(0% 0%, 100% 80%, 100% 100%, 0% 100%);
    }
    .peak-r-1 {
      grid-column: full-start / main-content-end;
      clip-path: polygon(0% 100%, 100% 0%, 100% 100%, 0% 100%);
    }
    .peak-r-2 {
      grid-column: main-content-end / full-end;
      clip-path: polygon(0% 0%, 100% 20%, 100% 100%, 0% 100%);
      margin-left: -1px; /* This is a terrible fix for Chrome (#35), and I feel terrible writing it. — EM */
    }
  }
}





/*

Homepage

*/
// Splash
.splash {
  padding: calc( 2rem + 5vw ) 0;
  position: relative;
}
.splash-logo {
  grid-column: 1 / -1;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 1rem;
}
.ec-wordmark-splash {
  display: block;
}
@media (max-width: 50em) {
  .ec-wordmark-splash {
    height: 30vw;
  }
  .word-changes,
  .word-everything {
    scale: 1.2;
  }
  .word-changes {
    transform: translate(-20%, 40%);
  }
  .word-everything {
    transform: translate(0, -50%);
  }
}
// By default, the tagline is righ-aligned within the row.
.splash-tagline {
  font-size: 1.125rem; /* 18/16 */
  line-height: 1.2;
  margin: 0 0 1em;
  grid-column: main-content;
  justify-self: end;
}
// The extra span element gives us the option of making this element sit
// on its own row with `display: block` OR inline with the rest of the text.
// (We’re only `display: block` for now, but this might change in the future!)
.splash-tagline-mmfb {
  display: block;
}
// Once we have enough room, let’s move the tagline out to the ~middle of the grid.
@media (min-width: 20em) {
  .splash-tagline {
    grid-column: 7 / main-content-end;
    justify-self: auto;
  }
}
// At a wider breakpoint, the tagline moves out a bit to (roughly) line up
// with “changes” in the logo, and we’ll bump up the font size.
@media (min-width: 40em) {
  .splash-tagline {
    font-size: 1.25rem; /* 20/16 */
    grid-column-start: 8;
  }
}
// At the widest breakpoint, we’ll bump up the font size a bit more.
@media (min-width: 50em) {
  .splash-tagline {
    font-size: 2.25rem; /* 36/16 */
  }
}

// Petal splash
.splash-bg {
  left: 0;
  margin: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.sect-home main {
  position: relative;
}
.petal {
  position: absolute;
  top: 0;
  width: 20vw;
  height: auto;
  opacity: 0;
  transition: opacity 1.1s, color var(--theme-transition--color);
}
.is--active .petal {
  opacity: 0.23;
}
.petal-1 {
  color: var( --color-petal-1, var(--logo-petals--mid-top) );
  transform: rotate(15deg);
  transition-delay: 0.25s;
}
.petal-2 {
  color: var( --color-petal-2, var(--logo-petals--outer-bottom) );
  transform: translate(20vw, 7.5em) rotate(-20deg) scale(1.2);
  transition-delay: 0.5s;
}
.petal-3 {
  color: var( --color-petal-3, var(--logo-petals--outer-top) );
  transform: translate(42vw, 12.65em) rotate(43deg) scale(0.7);
  transition-delay: 0.75s;
}
.petal-4 {
  color: var( --color-petal-4, var(--logo-petals--overlay-inner) );
  transform: translate(66vw, 13.9em) rotate(-35deg) scale(0.9);
  transition-delay: 1s;
}
@media (min-width: 54em) {
  .petal {
    height: 467px;
    width: 195px;
  }
  .petal-1 {
    transform: none;
  }
  .petal-2 {
    transform-origin: 50% 100%;
    transform: rotate(40deg) scale(1.3);
  }
  .petal-3 {
    transform: translateX(24.25rem) translateY(-1.3em) scale(90%);
  }
  .petal-4 {
    transform-origin: 0 50%;
    transform: translate(33.6rem, 30.25rem) rotate(-33deg) scale(1.2);
  }
}




// About ec
@media (max-width: 50em) {
  .ec-intro {
    margin-top: 1rem;
  }
}
@media (min-width: 50em) {
  .ec-intro-header {
    grid-column-end: span 6;
    margin: 0;
  }
}
.ec-intro-content > :first-child {
  font-size: 1.375rem; /* 22/16 */
  line-height: 1.4;
}
@media (min-width: 50em) {
  .ec-intro-content {
    grid-column: 7 / main-content-end;
  }
  .ec-intro-content > :first-child {
    font-size: 1.625rem; /* 26 / 16 */
    line-height: 1.3;
  }
}

// About Mandy / How We Work
.mb-headshot {
  border-radius: 50%;
}
@media (max-width: 50em) {
  .mb-intro.u-layout {
    display: block;
    margin: 0 0.5em;
  }
  .mb-headshot {
    float: left;
    width: 100px;
    margin-right: 10px;
  }
}
@media (min-width: 50em) {
  .mb-headshot {
    grid-column: 4 / span 2;
    width: 100%;
  }
  .mb-content {
    grid-column: 6 / span 7;
  }
}
@media (min-width: 50em) {
  .mb-header {
    grid-column-start: 4;
  }
}

// Testimonials
.testimonials {
  background-color: #D0D0D0;
  background-color: var(--color-accent);
  transition: background-color var(--theme-transition--color);
}
.testimonials-header,
.testimonials-content {
  grid-column: main-content;
}
.testimonials-header {
  text-align: center;
  margin: 3rem 0 1rem;
}
.testimonials-content {
  display: flex;
  gap: 2rem;
  overflow: auto;
  padding: 2rem 0;
  scroll-snap-stop: always;
  scroll-snap-type: x mandatory;
}
@media (min-width: 40em) {
  .testimonials {
    background-color: #D0D0D0;
    background-color: var(--color-accent);
  }
  .testimonials-header {
    align-items: center;
    display: flex;
    grid-column-end: span 4;
    margin: 0;
    text-align: left;
  }
  .testimonials-content {
    grid-column: 6 / -1;
    padding: 3rem;
  }
}
.testimonial-card {
  background-color: #FFF;
  background-color: unset;
  border-radius: 0.45em;
  color: var(--color-card-text, inherit);
  display: flex;
  flex-direction: column;
  flex: 0 0 21rem;
  font-size: 0.925em;
  line-height: 1.55;
  margin: 0;
  position: relative;
}
.testimonial-card:before,
.testimonial-quote {
  position: absolute;
  left: 50%;
  top: 0;
}
.testimonial-card:before {
  background-color: #414042;
  background-color: var(--color-card-accent, #414042);
  border-radius: 50%;
  content: "";
  height: 44px;
  width: 44px;
  margin-left: -22px;
  margin-top: -22px;
  z-index: 1;
}
.testimonial-quote {
  color: #FFF;
  height: 22px;
  width: 22px;
  margin-top: -11px;
  margin-left: -11px;
  z-index: 10;
}
.testimonial-body,
.testimonial-card address {
  padding: 1.5em;
}
.testimonial-body {
  background-color: var(--color-card-bg, hsla(0deg, 0%, 100%, 0.5));
  border-radius: 0.45em 0.45em 0 0;
  flex: 1;
}
.testimonial-body > :first-child {
  margin-top: 0;
}
.testimonial-body > :last-child {
  margin-bottom: 0;
}
.testimonial-card address {
  background-color: #EDEDED;
  background-color: var(--color-card-bg-alt, hsla(0deg, 0%, 100%, 0.7));
  border-radius: 0 0 0.45em 0.45em;
  flex: 0 0 3rem;
  font-size: 0.95em;
  font-style: normal;
  margin-top: auto;
  padding: 1em;
  text-align: center;
  text-transform: uppercase;
}
.testimonial-card cite {
  display: block;
  font-style: inherit;
  font-variation-settings: "slnt" 0;
  font-weight: bold;
}

// Clients
.clients {
  padding: 4rem 0;
}
.clients-header {
  color: inherit;
  margin: 0 auto 2rem;
}
.clients-list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem 3rem;
  justify-content: center;
}



/*

Blog index

*/
.index-list {
  --layout-hed-offset: 1.1em;
  max-width: 40em;
}
@media (min-width: 54em) {
  .index-list {
    grid-column-end: span 9;
    max-width: inherit;
  }
}
.index-item {
  margin-bottom: 3.5rem;
  position: relative;
}
.index-item:not(:last-child) {
  border-bottom: 1px solid #CCC;
  border-color: var(--color-text);
  padding-bottom: 3rem;
}
.index-item .date {
  display: block;
  flex: 0 0 21%;
  margin-bottom: 3rem;
}
@media (min-width: 50em) {
  .index-item .date {
    margin-bottom: 1rem;
  }
}
.hed-teaser {
  font-size: 1.45rem;
  margin: 0 0 0.25em;
}
@media (min-width: 50em) {
  .hed-teaser {
    font-size: 2rem;
  }
}
.desc-teaser {
  font-size: 1.125rem; /* 20/16 */
  font-weight: 350;
  margin: 0;
}
.hr-accent {
  color: var(--color-text);
  position: absolute;
  left: 50%;
  margin-left: -17px;
  bottom: -17px;
}
.index-item:last-child .hr-accent {
  display: none;
}
.link-item {
  padding-bottom: 1.5rem;
  padding-left: 40px;
  position: relative;
}
.link-item + .link-item {
  border-top: 1px solid #CCC;
  border-color: var(--color-text);
  margin-bottom: 1.5rem;
  padding-top: 1.5rem;
}
.link-item + .teaser {
  margin-top: 2rem;
}
.link-marker {
  align-items: center;
  background: var(--color-link-marker);
  border-radius: 50%;
  display: flex;
  height: 25px;
  justify-content: center;
  left: 0;
  margin-top: 0;
  position: absolute;
  top: 1.5rem;
  transition: background-color 0.25s;
  width: 25px;
}
.link-marker:focus,
.link-marker:hover {
  background: var(--color-text);
}
.link-item:first-child .link-marker {
  top: 0;
}
.link-icon {
  color: var(--color-bg);
}
@media (min-width: 40em) {
  .index-item {
    display: flex;
    justify-content: space-between;
  }
  .index-body {
    margin-left: var(--layout-gap-variable);
    flex: 1;
  }
  .index-body > .teaser:first-child {
    margin-top: var(--layout-hed-offset);
  }
  .index-body > .teaser:first-child .hed-teaser {
    margin-top: calc( var(--layout-hed-offset) * -1 );
  }
  .link-item {
    padding-left: 0;
  }
  .link-marker {
    left: -40px;
  }
}

// Prev/next links
.nav-prevnext {
  display: flex;
  flex-wrap: wrap;
  font-style: inherit;
  font-variation-settings: "slnt" -5;
  gap: 1rem;
  justify-content: space-between;
  max-width: 40em;
}
@media (min-width: 54em) {
  .nav-prevnext {
    margin-left: -1em;
    grid-column-end: span 9;
    max-width: inherit;
  }
}
.link-prevnext {
  font-weight: inherit;
  transition: font-variation-settings 0.2s;
}
.link-prevnext:focus,
.link-prevnext:hover {
  font-variation-settings: "slnt" 0;
}
.link-next:first-child:last-child {
  margin-left: auto;
}
.link-prevnext span {
  display: inline-block;
  text-decoration: none;
  padding: 0 0.25em;
}

// “Learn more about ec” promo
.ec-promo,
.promo-main {
  transition: background-color var(--theme-transition--color);
}
// Apply a background to ec-promo _unless_ it’s part of .has--footer-cap
.ec-promo {
  color: var(--ecpromo--color-text);
}
main:not(.has--footer-cap) .ec-promo {
  background: #D8D8D8;
  background: var(--color-accent-alt, #D8D8D8);
}
.promo-main {
  padding: 1rem 0;
  text-align: center;
}
.logo-promo {
  align-items: flex-end;
  background: var(--color-promo-logo-bg);
  border-radius: 50%;
  box-shadow: 0 0 0 8px #E4E4E4, 0 0 0 16px #C4C4C4;
  box-shadow: 0 0 0 11px var(--logo-petals--outer-top), 0 0 0 22px var(--logo-petals--overlay-inner);
  display: block;
  display: flex;
  height: 76px;
  justify-content: center;
  justify-self: center;
  margin: -1rem 1rem 1rem;
  padding: 8px;
  transition: box-shadow var(--theme-transition--color);
  width: 76px;
}
.logo-promo svg {
  width: 60px;
  height: auto;
}
.promo-hed {
  margin: 1rem 0;
}
.promo-body {
  font-size: 1.25rem;
  line-height: 1.3;
}
@media (min-width: 50em) {
  main:not(.has--footer-cap) .ec-promo {
    background: initial;
  }
  .promo-main {
    background: var(--color-accent-alt, #D8D8D8);
    padding: 2.5rem 0;
    text-align: left;
  }
  .logo-promo {
    grid-column-start: 10;
    grid-row: 1;
    height: 122px;
    justify-self: initial;
    margin-top: 1rem;
    width: 122px;
  }
  .logo-promo svg {
    width: auto;
  }
  .promo-hed {
    font-size: 3rem;
    align-self: center;
    grid-column-start: 3;
  }
  .promo-body {
    grid-column: 5 / 10;
  }
}


/*

Blog post / “content” page

*/
.post-content {
  --flow-space: 1.2em;
  font-size: 1rem;
  margin-bottom: 4rem;
}
@media (min-width: 40em) {
  .post-content {
    font-size: 1.15rem;
    line-height: 1.5;
    margin-bottom: 6rem;
  }
}
@media (max-width: 54em) {
  .post-content {
    max-width: 40em;
  }
}
.hed-article {
  font-size: 1.75rem; /* 28/16 */
  font-weight: 700;
  line-height: 1.1;
}
@media (min-width: 30em) {
  .hed-article {
    font-size: 3rem; /* 48/16 */
  }
}
@media (min-width: 54em) {
  .hed-article {
    font-size: 4.3125rem; /* 69/16 */
    line-height: 1;
  }
}
.article-meta {
  font-size: 1rem;
  font-variation-settings: "slnt" -5;
}
// Article metadata (date, author)
@media (max-width: 54em) {
  .article-meta {
    display: block;
  }
}
@media (min-width: 54em) {
  .article-meta {
    margin: 3rem 0 2rem;
  }
}
.date {
  grid-column-end: span 2;
}
.by {
  grid-column-start: 3;
}
.article-meta a {
  font-weight: inherit;
}
// Link posts
.hed-link {
	font-size: 2rem;
  line-height: 1.1;
	margin: 0 0 0.25em;
	font-weight: bold;
}
.link-meta {
  margin: 0;
}
@media (min-width: 54em) {
  .hed-link,
  .link-meta {
    grid-column-start: 4;
  }
}
.flag-link {
  align-items: center;
  background: var(--color-peak-bg);
  border-radius: 50%;
  display: inline-flex;
  height: 1.5em;
  justify-content: center;
  margin-top: 0.25em;
  width: 1.5em;
}
@media (min-width: 40em) {
  .flag-link {
    float: left;
    margin-left: -40px;
    margin-top: 0;
  }
}

// Generics for post content
// Additional spacing around key elements
.post-content blockquote,
.post-content img {
  margin-top: calc( var(--flow-space) * 2 );
  margin-bottom: var(--flow-space);
}
.post-content blockquote {
  border-left: 0.5em solid;
  line-height: 1.6;
  margin-left: -0.9em;
  padding-left: 1.25em;
}
@media (min-width: 40em) {
  .post-content blockquote {
    padding-left: 2em;
  }
}
@media (min-width: 54em) {
  .post-content blockquote {
    padding-left: 1.25em;
    margin-left: -2em;
  }
}
@media (min-width: 60em) {
  .post-content blockquote {
    margin-left: 0;
  }
}
.stopmark {
  padding-left: 0.5em;
  vertical-align: -0.2em;
  width: 0.9375rem;
}
// Inline footnote refs
.footnote {
  font-size: 0.85em;
  text-decoration: none;
}
// Footnote list
sup {
	line-height: 0;
}

.footnotes {
  font-size: 0.85em;
  margin-top: 3rem;
  padding-top: 1rem;
  border-top: 1px solid;
}
.footnotes ol {
  padding-left: 1em;
}
@media (min-width: 56em) {
  .footnotes ol {
    padding: 0;
  }
}
.reversefootnote {
  -webkit-appearance: none;
  appearance: none;
  font-weight: normal;
  background: var(--theme--color-accent-alt);
  border-radius: 0.25em;
  padding: 0.15em;
  margin-left: 0.15em;
  text-decoration: none;
}


// We’re manually replicating .u-flow here, as we can’t
// guarantee the class’ll be generated in post content
.post-content blockquote > * {
  margin: 0;
}
.post-content blockquote > p:not(:first-child) {
  text-indent: 1.5em;
}
.post-content address {
  font-style: inherit;
  margin-top: var(--flow-space, 1em);
  text-align: right;
  text-indent: 0;
}
.post-content address:before {
  content: "— ";
}
.post-content li:not(:last-child) {
  margin-bottom: 0.75em;
}
.post-content .hug-full {
  grid-column: full;
}
@media (min-width: 54em) {
  .post-content > :not([class]),
	.post-content > .epi,
  .post-content > img,
  .post-content .footnotes {
    grid-column-end: span 8;
  }
  .sect-blog .post-content > :not([class]),
	.sect-blog .post-content > .epi,
  .sect-blog .post-content > img,
  .sect-blog .post-content .footnotes {
    grid-column-start: 4;
  }
  .sect-blog .post-content .hug-start {
    grid-column: main-content-start / span 9;
  }
  .sect-blog .post-content .hug-full {
    grid-column: main-content;
  }
}


/*

Form styles

*/
.field-group {
  --flow-space: 0.5em;
  max-width: 32em;
  margin-bottom: 1.5em;
}
.form label {
  font-weight: 400;
}
.field-group:has([required]) label:before {
  content: "* ";
  color: var(--logo-petals--overlay-inner);
  font-weight: 700;
  margin-left: 0;
}
@media (min-width: 68em) {
  .field-group:has([required]) label:before {
    margin-left: -0.75em;
  }
}
.form [type="text"],
.form [type="email"],
.form textarea {
  box-sizing: border-box;
  padding: 0.25em;
}
.form textarea {
  height: 7em;
}
@media (min-height: 40em) {
  .form textarea {
    height: 14em;
  }
}
.field-group > * {
  display: block;
  width: 100%;
}
.field-group-inset {
  display: flex;
  gap: calc( var(--flow-space) / 2);
  align-items: first baseline;
}
.field-group-inset label {
  flex: 1;
}
.field-suggestions {
  font-size: 0.90em;
  font-variation-settings: "slnt" -5;
}

p.list-label {
	margin-bottom: 0.3em;
}

ul.option-list {
	margin-top: 0.5em;
}

/*

Extra stuff for the subscribe page, probably belongs somewhere else ALSO this is probably all the wrong ways to do this but here we are —mandy

*/

blockquote footer.praise {
	padding-left: 20px;
	color: var(--color-text);
}

blockquote footer.praise:before {
	content: "—";
	margin-left: -15px;
}

form.sub-page {
	background-color: transparent;
}

form.sub-page div.subscribe-content {
	padding-left: 0;
}

form.sub-page input {
	border-color: var(--color-text);
	color: #666;
	background-color: #fff;

	&:focus {
		border-color: var(--color-text);
	}
}


.sign-up-btn {
  background-color: #414042;
  color: #fff;
  border: none;
  border-radius: 0.3em;
  font-weight: bold;
  font-size: 0.875rem;
  padding: 0.8rem 1.0rem;
  text-transform: uppercase;
}

.sign-up-btn:focus,
.sign-up-btn:hover {
  background-color: #414042;
  outline: none;
}

/*

work/shops

*/

dt {
	font-weight: 600;
}

dd {
	margin-left: 0;
	margin-bottom: 20px;
}

.cta {
	padding: 1.4rem;
	background-color: var(--color-accent);
	border-radius: 0.3em;
}

.cta h2 {
	font-size: 1.3rem;
	font-weight: 600;
}

@media (min-width: 54em) {
	.cta {
  	grid-column-end: span 8;
  } 
}


/* fucking bots */

p.honey {
	display: none;
}


/* etcetera */

.epi {
	margin-left: 80px;
}
